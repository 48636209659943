// We use this file as an entrypoint for Webpack.

// By requiring context we specify that all files
// in [folder] should be required and bundled
// The require.context exports an API with functions to
// resolve the individual modules

// https://github.com/webpack/docs/wiki/context

function requireAll (r) {
    r.keys().forEach(r);
}

// The Script Loader plugin executes scripts once in global context
// This is great for legacy scripts
// https://github.com/webpack-contrib/script-loader

require('core-js/fn/array/from');

requireAll(require.context('script-loader!./helpers/', true, /\.js$/));

requireAll(require.context('script-loader!./features/', true, /\.js$/));

